@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../node_modules/@cxc/react-fds-components/index.css";


@font-face {
    font-family: "fordngbs-antenna-cond-medium";
    src: url("https://www.ford.com/cmslibs/etc/designs/common/skin/ford/fonts/AntennaCondMedium/antenna-cond-medium.woff");
}

@font-face {
    font-family: "fordngbs-antenna-cond-regular";
    src: url("https://www.ford.com/cmslibs/etc/designs/common/skin/ford/fonts/AntennaCondRegular/antenna-cond-regular.woff");
}

@font-face {
    font-family: "fordngbs-antenna-medium";
    src: url("https://www.ford.com/cmslibs/etc/designs/common/skin/ford/fonts/AntennaMedium/antenna-medium.woff");
}

@font-face {
    font-family: "fordngbs-antenna-re";
    src: url("https://www.ford.com/cmslibs/etc/designs/common/skin/ford/fonts/AntennaRegular/regular.woff");
}

@font-face {
    font-family: "fordngbs-antenna-extra-light";
    src: url("https://www.ford.com/cmslibs/etc/designs/common/skin/ford/fonts/AntennaExtraLight/extraLight.woff");
}

@font-face {
    font-family: "fordngbs-antenna-light";
    src: url("https://www.ford.com/cmslibs/etc/designs/common/skin/ford/fonts/AntennaLight/light.woff");
}

@font-face {
    font-family: "fordngbs-antenna-bold";
    src: url("https://www.ford.com/cmslibs/etc/designs/common/skin/ford/fonts/AntennaBold/bold.woff");
}

* {
    box-sizing: border-box;
}

body {
    margin: 0 auto !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 82%;
}

h1 {
    font-family: "fordngbs-antenna-light", sans-serif;
    font-weight: normal;
}

p {
    font-family: "fordngbs-antenna-re", sans-serif;
}


.ford-font-reg {
    font-family: "fordngbs-antenna-re", sans-serif;
}

.ford-font-med {
    font-family: "fordngbs-antenna-medium", sans-serif;
}

.ford-font-cond-reg {
    font-family: "fordngbs-antenna-cond-regular", sans-serif;
}

.ford-font-cond-med {
    font-family: "fordngbs-antenna-cond-medium", sans-serif;
}

a {
    @apply text-primary;
}

a:active,
a:hover {
    @apply text-secondary;
}

.fds-app .fmc-tabs__tab {
    margin: -1.6rem 2rem;
    background-color: #F7F7F7;
    width: 33rem;
}

.fds-app .fmc-tabs__tab--hover::after {
    display: none !important;
}


.fds-app .fmc-tabs__tablist {
    width: 95% !important;
    display: flex !important;
    justify-content: space-between !important;
}


button[id*='FDShTabsImagesTab'] {
    font-size: 2.4rem !important
}

.caret-override {
    /* Removes the default <select> styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Positions background arrow image */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: bottom 50% right 1rem;
}